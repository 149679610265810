<div class='modal fade' bsModal #receiptModal='bs-modal' tabindex='-1' role='dialog' aria-hidden='true'>
  <div class='modal-dialog modal-sm' style='width: 400px'>
    <div class='modal-content'>
      <div class='modal-header clearfix'>
        <div class='modal-header-close'>
          <button type='button' class='close pull-right' aria-label='Close' (click)='close()'>
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div style='width: 100%'>
          <div class='modal-logo'>
            <img src='img/splash.png' alt='logo-umojo'>
          </div>
        </div>
        <div class='modal-header-title'>
          <span class='modal-title pull-left'>Receipt</span>
        </div>
      </div>
      <div class='modal-body'>
        <form #form="ngForm" *ngIf="initialized" novalidate>
          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main' [class.has-error]="customerName | hasErrors: validate">
              <label>Customer Name<sup class='required-star'></sup></label>
              <input
                class="form-control"
                maxLength='220'
                type="text"
                [(ngModel)]="receipt.CustomerName"
                name="customerName"
                #customerName="ngModel"
                required
              />
            </div>
            <app-validation-errors [model]="customerName" [validate]="validate"></app-validation-errors>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>Address</label>
              <input class="form-control" type="text" maxlength='220' [(ngModel)]="receipt.Address" name="address" />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>City</label>
              <input class="form-control" maxlength='220' type="text" [(ngModel)]="receipt.City" name="city" />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>State / Province</label>
              <input class="form-control" type="text" maxlength='220' [(ngModel)]="receipt.StateProvince" name="address" />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>Zip Code</label>
              <input class="form-control" maxlength='220' type="text" [(ngModel)]="receipt.ZipCode" name="zipCode" />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>Phone</label>
              <input class="form-control" maxlength='20' type="text" [(ngModel)]="receipt.Phone" name="phone" />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main' [class.has-error]="email | hasErrors: validate">
              <label>Email Address<sup class='required-star'></sup></label>
              <input
                class="form-control"
                maxlength='50'
                type="email"
                [(ngModel)]="receipt.CustomerEmail"
                name="email"
                #email="ngModel"
                required
              />
            </div>
            <app-validation-errors [model]="email" [validate]="validate"></app-validation-errors>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>License Plate</label>
              <input class="form-control" type="text" maxlength='220' [(ngModel)]="receipt.LicensePlate" name="LicensePlate" />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>Parking Location</label>
              <input name='ParkingLocation' maxlength='220' type='text'
                     class='form-control' disabled [value]='receipt.ParkingLocation' />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main' [class.has-error]="cashCreditAmount | hasErrors: validate">
              <label>Cash / Credit Amount<sup class='required-star'></sup></label>
              <input
                class="form-control receipt-cash"
                type="text"
                [(ngModel)]="receipt.CashCreditAmount"
                name="cashCreditAmount"
                #cashCreditAmount="ngModel"
                required
                min='0.00' step='1' max='1000000'
              />
              <span class='receipt-cash-currency'>$</span>
            </div>
            <app-validation-errors [model]="cashCreditAmount" [validate]="validate"></app-validation-errors>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>Date / Time</label>
              <input name='DateOfTransaction' maxlength='220' type='text' class='form-control' disabled
                     [value]='receipt.DateOfTransaction' />
            </div>
          </div>
        </form>
      </div>

      <div class='modal-footer'>
        <button class='button' type='reset' (click)='close()'>Cancel</button>
        <button class='button button--primary' (click)='save()'>Send</button>
      </div>

    </div>
  </div>
</div>
